import { Modal } from 'react-bootstrap';
import './ClaimModal.scss';
import { Address } from 'viem';
import claimABI from '../../actions/vesting/claim.json';
import { useEffect, useMemo, useState } from 'react';
import { BIG_ZERO, NULL_ADDRESS, getBalanceNumber, getFullDisplayBalance } from '../../utils/formatbalance';
import { useNetwork, useWalletClient } from 'wagmi';
import useAccount from '../../hooks/useAccountOverride';
import { getChainClient } from '../../utils/zunami';
import BigNumber from 'bignumber.js';
import moment from 'moment';
import { log } from '../../utils/logger';
import { Preloader } from '../Preloader/Preloader';
import { waitForTransaction } from '@wagmi/core';

export interface compoundData {
    collectedRewards: number;
    
    lastCompound: string;
}

interface ClaimModalProps {
    onHide?: Function;
    show: boolean;
    eligibleContracts: Array<Address>,
}

export const ClaimModal = (props: ClaimModalProps): JSX.Element => {
    const { address } = useAccount();
    const wallet = useWalletClient();
    const { chain } = useNetwork();

    const [transactionError, setTransactionError] = useState(false);
    const [transactionId, setTransactionId] = useState<string | undefined>(undefined);
    const [pendingTx, setPendingTx] = useState(false);
    const [selectedContract, setSelectedContract] = useState(props.eligibleContracts[0]);
    const [vestingAmount, setVestingAmount] = useState(BIG_ZERO);
    const [claimable, setClaimable] = useState(BIG_ZERO);
    const [alreadyClaimed, setAlreadyClaimed] = useState(BIG_ZERO);
    const [vestingEnd, setVestingEnd] = useState<BigInt>(BigInt(0));

    useMemo(() => {
        if (!wallet.data || !address || !selectedContract || !props.show) {
            return;
        }

        function getVestingAmount() {
            console.log('[Vesting] allocation() call');

            try {
                // @ts-ignore
                getChainClient(1).readContract({
                    address: selectedContract,
                    // @ts-ignore
                    chain: chain,
                    abi: claimABI,
                    functionName: 'allocation',
                    args: [address],
                    account: address || NULL_ADDRESS,
                    // @ts-ignore
                }).then((result: BigInt) => {
                    setVestingAmount(new BigNumber(result.toString()));
                });
            } catch (e: any) {
                alert(`Can't call allocation() of vesting contract. Reason: ${e.message}`);
            }
        }

        function getClaimableStatus() {
            console.log('[Vesting] claimable() call');

            try {
                // @ts-ignore
                getChainClient(1).readContract({
                    address: selectedContract,
                    // @ts-ignore
                    chain: chain,
                    abi: claimABI,
                    functionName: 'claimable',
                    args: [address],
                    account: address || NULL_ADDRESS,
                    // @ts-ignore
                }).then((result: BigInt) => {
                    setClaimable(new BigNumber(result.toString()));
                });
            } catch (e: any) {
                alert(`Can't call claimable() of vesting contract. Reason: ${e.message}`);
            }
        }

        function getClaimedStatus() {
            console.log('[Vesting] claimed() call');

            try {
                // @ts-ignore
                getChainClient(1).readContract({
                    address: selectedContract,
                    // @ts-ignore
                    chain: chain,
                    abi: claimABI,
                    functionName: 'claimed',
                    args: [address],
                    account: address || NULL_ADDRESS,
                    // @ts-ignore
                }).then((result: BigInt) => {
                    setAlreadyClaimed(new BigNumber(result.toString()));
                });
            } catch (e: any) {
                alert(`Can't call claimed() of vesting contract. Reason: ${e.message}`);
            }
        }

        function getVestingEnd() {
            console.log('[Vesting] vestingEnd() call');

            try {
                // @ts-ignore
                getChainClient(1).readContract({
                    address: selectedContract,
                    // @ts-ignore
                    chain: chain,
                    abi: claimABI,
                    functionName: 'vestingEnd',
                    account: address || NULL_ADDRESS,
                    // @ts-ignore
                }).then((result: BigInt) => {
                    log(`[Vesting] End: ${result}`);
                    setVestingEnd(result);
                });
            } catch (e: any) {
                alert(`Can't call vestingEnd() of vesting contract. Reason: ${e.message}`);
            }
        }

        getVestingAmount();
        getClaimableStatus();
        getClaimedStatus();
        getVestingEnd();
    }, [selectedContract, address, chain, wallet.data, props.show]);

    useEffect(() => {

    }, [props.eligibleContracts]);

    const canClaim = useMemo(() => {
        return claimable.isGreaterThan(BIG_ZERO);
        // return alreadyClaimed.isLessThanOrEqualTo(claimable);
    }, [claimable]);

    return (
        <Modal
            show={props.show}
            backdrop="static"
            animation={false}
            keyboard={false}
            centered
            onHide={() => {
                if (props.onHide) {
                    props.onHide();
                }
            }}
            className="ClaimModal"
        >
            <Modal.Header closeButton className="ps-4 pe-4 pt-4">
                <Modal.Title>ZUN Vesting</Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex gap-2 flex-column justify-content-center ps-5">
                <div className="row">
                    <div className="col-xs-12 col-md-4 chameleon-text-gray">Contract</div>
                    <div className="col-xs-12 col-md-7 chameleon-text">
                        <select
                            className="form-control"
                            style={{ appearance: 'auto' }}
                            onChange={(value: any) => {
                                setSelectedContract(value.currentTarget.value);
                            }}
                        >
                            {
                                props.eligibleContracts.map((contract: Address) => 
                                    <option key={contract} value={contract}>{contract}</option>
                                )
                            }
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-md-4 chameleon-text-gray">ZUN vesting amount</div>
                    <div className="col-xs-12 col-md-7 chameleon-text">
                        {getBalanceNumber(vestingAmount).toNumber().toLocaleString('en')}
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-md-4 chameleon-text-gray">End date</div>
                    <div className="col-xs-12 col-md-7 chameleon-text">
                        {moment(Number(vestingEnd) * 1000).toLocaleString()}
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-md-4 chameleon-text-gray">Claimable</div>
                    <div className="col-xs-12 col-md-7 chameleon-text">
                        {getFullDisplayBalance(claimable)}
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-md-4 chameleon-text-gray">Already claimed</div>
                    <div className="col-xs-12 col-md-7 chameleon-text">
                        {getFullDisplayBalance(alreadyClaimed)}
                    </div>
                </div>
                <div className="row mb-4 mt-2">
                    <div className="col d-flex gap-2 align-items-center">
                        <button
                            className={`zun-button ${canClaim ? '' : 'disabled'}`}
                            onClick={async () => {
                                if (!wallet || !wallet.data) {
                                    return;
                                }

                                setPendingTx(true);

                                try {
                                    const txHash = await wallet.data.writeContract({
                                        address: selectedContract,
                                        chain: chain,
                                        abi: claimABI,
                                        functionName: 'claim',
                                        account: address,
                                    });

                                    setTransactionId(txHash);

                                    waitForTransaction({ hash: txHash }).then(() => {
                                        setPendingTx(false);
                                        setAlreadyClaimed(claimable);
                                        setClaimable(BIG_ZERO);
                                    });
                                } catch (error: any) {
                                    setTransactionError(error.message);
                                    alert(error.message);
                                    log(`❗️ Error while claim: ${error.message}`);
                                    setPendingTx(false);
                                }
                            }}
                        >Claim</button>
                        {(pendingTx) && <Preloader className="ms-2" />}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};
