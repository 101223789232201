import { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import './SideBar.scss';
import { BigNumber } from 'bignumber.js';
import { ThemeSwitcher } from '../ThemeSwitcher/ThemeSwitcher';
import { WalletButton } from '../WalletButton/WalletButton';
import { VerificationInfoPanel } from '../VerificationInfoPanel/VerificationInfoPanel';

export interface ZunamiInfo {
    tvl: BigNumber;
    apy: number;
    apr: number;
    curve: Object;
}

export interface ZunamiInfoFetch {
    data: any;
    isLoading: boolean;
    error: any;
}

interface SideBarProps {
    isMainPage: boolean;
    tvl?: string;
    children?: string | JSX.Element | JSX.Element[];
}

export const SideBar = (props: SideBarProps): JSX.Element => {
    const [tvl, setTvl] = useState('0');
    const [open, setOpen] = useState(false);
    const [gasPrice, setGasPrice] = useState('');

    useEffect(() => {
        fetch(
            'https://brain.bitfo.com/api/gas/estimated_base_fees'
        )
            .then((response) => response.json())
            .then((data) => {
                // setGasPrice(data.result.ProposeGasPrice);
                setGasPrice(data[data.length - 1].value.toFixed(0))
            })
            .catch(() => {
                setGasPrice('n/a');
            });

        if (props.tvl) {
            setTvl(props.tvl);
        }
    }, [props.tvl]);

    return (
        <Col id="sidebar-col" className={'SidebarColumn'}>
            <div className="Sidebar">
                <div className="Sidebar__Header d-flex align-items-center">
                    <svg
                        width="132"
                        height="22"
                        viewBox="0 0 132 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M88.7761 1.08348L89.5835 0.276139C89.671 0.188593 89.775 0.119147 89.8894 0.0717671C90.0037 0.0243875 90.1263 0 90.2501 0C90.374 0 90.4965 0.0243875 90.6109 0.0717671C90.7253 0.119147 90.8292 0.188593 90.9168 0.276139L91.7241 1.08348C91.8117 1.17103 91.8811 1.27496 91.9285 1.38935C91.9759 1.50373 92.0003 1.62633 92.0003 1.75013C92.0003 1.87394 91.9759 1.99654 91.9285 2.11092C91.8811 2.22531 91.8117 2.32924 91.7241 2.41678L90.9168 3.22413C90.8292 3.31168 90.7253 3.38112 90.6109 3.4285C90.4965 3.47588 90.374 3.50027 90.2501 3.50027C90.1263 3.50027 90.0037 3.47588 89.8894 3.4285C89.775 3.38112 89.671 3.31168 89.5835 3.22413L88.7761 2.41678C88.5993 2.23998 88.5 2.00018 88.5 1.75013C88.5 1.50009 88.5993 1.26029 88.7761 1.08348ZM88.7365 18.5383V4.91466H91.7638V18.5383H88.7365Z"
                            fill="black"
                        />
                        <path
                            d="M80.6344 4.53906C78.7191 4.53906 77.0007 5.47502 75.8222 6.95933C75.2204 6.20381 74.4558 5.5938 73.5855 5.17475C72.7152 4.75571 71.7615 4.53841 70.7956 4.53906C70.3021 4.53878 69.8107 4.60178 69.3333 4.72653C68.9011 4.84211 68.5004 5.05348 68.161 5.34497C67.8216 5.63646 67.5521 6.0006 67.3726 6.4104V4.91502H65.9493V4.91732H64.3623V18.541H67.3896V11.7292C67.3896 9.2215 68.9143 7.18815 70.7956 7.18815C72.6765 7.18815 74.2015 8.8826 74.2015 10.9727V18.541H77.2288V11.7292C77.2288 9.2215 78.7535 7.18815 80.6344 7.18815C82.5156 7.18815 84.0403 8.8826 84.0403 10.9727V18.541H87.0676V10.9727C87.0677 10.1278 86.9014 9.29117 86.5781 8.51059C86.2549 7.73001 85.781 7.02075 85.1836 6.42332C84.5862 5.8259 83.877 5.352 83.0964 5.0287C82.3159 4.70539 81.4793 4.53901 80.6344 4.53906Z"
                            fill="black"
                        />
                        <path
                            d="M60.8615 4.91736V4.91506H59.4383V6.68956C59.1184 6.05729 58.5877 5.55665 57.9378 5.27424C56.8068 4.78579 55.5874 4.53555 54.3554 4.5391C49.8625 4.5391 46.2197 7.7579 46.2197 11.7292C46.2197 15.6998 49.0996 18.9193 52.653 18.9193C56.206 18.9193 58.9229 16.0394 58.9229 12.4857H55.8952C55.8952 13.4482 55.7586 14.3734 55.226 15.0413C54.9181 15.4275 54.5265 15.7388 54.0808 15.9516C53.6351 16.1645 53.1469 16.2734 52.653 16.2702C50.7717 16.2702 49.247 14.2368 49.247 11.7292C49.247 9.22153 51.5349 7.18819 54.3554 7.18819C57.177 7.18819 59.4645 9.22153 59.4645 11.7292V18.541H62.4918V4.91736H60.8615Z"
                            fill="black"
                        />
                        <path
                            d="M38.7299 4.53925C37.4722 4.53103 36.2268 4.78765 35.0748 5.29243C34.4326 5.57898 33.9118 6.08253 33.6038 6.71474V4.91521H32.1805V4.91751H30.5625V18.5412H33.5898V11.7293C33.5898 9.22169 35.8777 7.18834 38.6982 7.18834C40.5791 7.18834 42.1038 8.88279 42.1038 10.9728V18.5412H45.1311V10.9922C45.1339 9.28885 44.462 7.65372 43.2625 6.44444C42.0629 5.23516 40.4332 4.55016 38.7299 4.53925Z"
                            fill="black"
                        />
                        <path
                            d="M25.6556 4.91797V11.7298C25.6556 14.2375 23.3677 16.2708 20.5475 16.2708C18.6663 16.2708 17.1416 14.5763 17.1416 12.4863V4.91797H14.1143V12.467C14.1116 14.1703 14.7835 15.8053 15.9831 17.0146C17.1826 18.2238 18.8122 18.9089 20.5155 18.9199C21.7732 18.9281 23.0186 18.6715 24.1706 18.1667C24.8132 17.8796 25.3343 17.3756 25.6426 16.7428V18.5416H28.6829V4.91797H25.6556Z"
                            fill="black"
                        />
                        <path
                            d="M0 6.38923V7.59834H8.23805L0 15.7891V18.5273H12.5131V15.7891H11.7606V15.7889H4.54665L4.99689 15.271C5.02644 15.24 5.05562 15.2085 5.08388 15.1762L5.89789 14.2459L12.5131 7.47187V4.92188H0V6.38923Z"
                            fill="black"
                        />
                        <rect x="98" width="34" height="22" rx="11" fill="#474747" />
                        <path
                            d="M114.39 7.78427L110.714 17H107.697L104 7.78427H106.486L107.718 11.3529C108.164 12.6666 108.632 13.9411 109.014 15.1961H109.439C109.822 13.9411 110.289 12.6274 110.735 11.3529L111.968 7.78427H114.39ZM120.432 15.098C119.285 15.098 117.627 15.1764 116.459 15.2941C116.544 13.8824 117.309 13.353 119.901 12.7058C122.918 12.0392 124.894 10.9608 124.894 8.70582C124.894 6.21561 123.194 5 120.113 5C116.714 5 115.141 6.745 114.78 9.54896L117.032 9.8039C117.287 7.82347 118.243 6.84311 120.092 6.84311C121.792 6.84311 122.514 7.56854 122.514 8.62741C122.514 9.84311 121.77 10.4706 118.732 11.2549C115.694 12.0392 114.376 13.1176 114.376 15.7843C114.376 16.2157 114.376 16.6078 114.398 17H125V15.098H120.432Z"
                            fill="white"
                        />
                    </svg>
                    <span className="ms-4 badge badge-pill badge-light d-none d-lg-flex align-items-center">
                        <span className="me-2">TVL</span>
                        <span className="text-primary me-2 vela-sans">
                            {`${`$${Number(tvl).toLocaleString('en', {
                                maximumFractionDigits: 0,
                            })}`}`}
                        </span>
                        <svg
                            width="18"
                            height="14"
                            viewBox="0 0 18 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1.96 10.105C1.704 11.5504 1.21333 12.3706 1 12.6H17V2.18967C16.7867 2.36174 16.328 2.72309 16.2 2.79192C16.04 2.87796 14.12 2.87796 13.64 2.79192C13.16 2.70589 13.0981 1.43471 12.84 1.15724C12.68 0.985213 12.1594 0.950992 11.88 1.0712C11.08 1.41539 11.56 2.96404 11.24 3.2221C10.9889 3.42459 10.44 3.48018 9.8 3.39414C9.16 3.30811 8.52 3.56629 8.2 3.82433C7.87994 4.08241 7.56 5.71719 7.08 6.1473C6.84709 6.356 6.44 6.23333 5.96 6.31937C5.48 6.40541 5.64 6.83566 5.16 7.00773C4.68 7.1798 3.08 6.74962 2.6 7.09369C2.31196 7.30016 2.28 8.2982 1.96 10.105Z"
                                fill="url(#paint0_linear_101_697)"
                                fillOpacity="0.16"
                            />
                            <path
                                d="M1 12.6C1.21333 12.3706 1.704 11.5504 1.96 10.105C2.28 8.2982 2.31196 7.30016 2.6 7.09369C3.08 6.74962 4.68 7.1798 5.16 7.00773C5.64 6.83566 5.48 6.40541 5.96 6.31937C6.44 6.23333 6.84709 6.356 7.08 6.1473C7.56 5.71719 7.87994 4.08241 8.2 3.82433C8.52 3.56629 9.16 3.30811 9.8 3.39414C10.44 3.48018 10.9889 3.42459 11.24 3.2221C11.56 2.96404 11.08 1.41539 11.88 1.0712C12.1594 0.950992 12.68 0.985213 12.84 1.15724C13.0981 1.43471 13.16 2.70589 13.64 2.79192C14.12 2.87796 16.04 2.87796 16.2 2.79192C16.328 2.72309 16.7867 2.36174 17 2.18967"
                                stroke="#FF7A00"
                                strokeWidth="1.1"
                                strokeLinecap="round"
                            />
                            <defs>
                                <linearGradient
                                    id="paint0_linear_101_697"
                                    x1="9"
                                    y1="1"
                                    x2="9"
                                    y2="12.6"
                                    gradientUnits="userSpaceOnUse"
                                >
                                    <stop stopColor="#F9A312" />
                                    <stop offset="1" stopColor="#F84C01" />
                                </linearGradient>
                            </defs>
                        </svg>
                    </span>
                </div>
                <div className="Sidebar__Content">
                    <div
                        className="d-flex align-items-center d-lg-none mt-4"
                        style={{ gap: '5px' }}
                    >
                        <span className="badge badge-pill badge-light d-flex align-items-center">
                            <span className="me-2">TVL</span>
                            <span className="text-primary me-2 vela-sans">
                                {`${`$${Number(tvl).toLocaleString('en', {
                                    maximumFractionDigits: 0,
                                })}`}`}
                            </span>
                        </span>
                        <button className="btn btn-light btn-sm d-flex align-items-center">
                            <svg
                                className="me-2"
                                width="4"
                                height="4"
                                viewBox="0 0 4 4"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle cx="2" cy="2" r="2" fill="#84B900" />
                            </svg>
                            <svg
                                width="15"
                                height="15"
                                viewBox="0 0 392.69 348.59"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g data-name="Слой 1">
                                    <path
                                        fill="#000000"
                                        d="m164.96 54.505h-55.693c-27.802 0-50.421 22.21-50.421 49.509v46.257c0 27.3 22.619 49.51 50.42 49.51h55.694c27.802 0 50.421-22.21 50.421-49.51v-46.257c0-27.3-22.62-49.51-50.42-49.51zm31.07 49.509v46.257c0 16.796-13.938 30.462-31.07 30.462h-55.693c-17.132 0-31.07-13.666-31.07-30.462v-46.257c0-16.796 13.938-30.461 31.07-30.461h55.693c17.132 0 31.07 13.665 31.07 30.46z"
                                    />
                                    <path
                                        fill="#000000"
                                        d="M392.674 285.498V145.331c-.005-18.889-5.275-35.307-15.662-48.798-10.352-13.444-25.957-24.187-46.384-31.931-4.967-1.89-10.575.556-12.5 5.446a9.331 9.331 0 0 0 .15 7.245c1.043 2.355 2.958 4.161 5.394 5.084 16.926 6.417 29.169 14.644 37.43 25.153 8.218 10.454 12.216 22.833 12.221 37.845l.006 140.408c.176 5.785-2.185 11.396-6.478 15.39-3.966 3.686-9.054 5.57-13.964 5.169-9.765-.798-17.597-10.424-17.1-21.088v-73.116c-.023-8.626-2.063-30.666-20.457-48.987-14.38-14.32-31.433-18.755-41.111-20.12V78.062C274.219 35.019 238.548 0 194.702 0H79.516C35.67 0 0 35.019 0 78.063v192.47c0 43.042 35.67 78.06 79.516 78.06h115.186c43.846 0 79.517-35.018 79.517-78.06V162.334c7.752 1.473 18.259 5.157 27.345 14.207 13.743 13.688 14.857 30.73 14.872 35.622l.01 72.649c-.981 20.689 14.649 38.862 34.836 40.512.935.078 1.911.118 2.904.118 9.51 0 18.727-3.703 25.956-10.425 8.301-7.721 12.87-18.496 12.532-29.519ZM254.866 270.57c-.02 32.519-27.01 58.975-60.164 58.975H79.516c-33.175 0-60.164-26.473-60.164-59.012V78.063c0-32.542 26.989-59.015 60.164-59.015h115.186c33.175 0 60.164 26.473 60.164 59.014v192.471h1.5l-1.5.037Z"
                                    />
                                </g>
                            </svg>
                            <span className="ms-2">{gasPrice}</span>
                        </button>
                        <ThemeSwitcher />
                        <WalletButton />
                        <div
                            className="nav-menu"
                            onClick={() => {
                                // setOpen(!open);
                                document
                                    .getElementById('MobileSidebar')
                                    ?.classList.toggle('active');
                                document.body.classList.toggle('overflow');
                            }}
                        >
                            {open && (
                                <svg
                                    width="21"
                                    height="21"
                                    viewBox="0 0 21 21"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1 19.3848L19.3848 0.99999"
                                        stroke="#000000"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                    />
                                    <path
                                        d="M1 1L19.3848 19.3848"
                                        stroke="#000000"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                    />
                                </svg>
                            )}
                            {!open && (
                                <svg
                                    width="35"
                                    height="10"
                                    viewBox="0 0 35 10"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M13.2793 9L34.0002 9"
                                        stroke="#000000"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                    />
                                    <path
                                        d="M1 1H34"
                                        stroke="#000000"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                    />
                                </svg>
                            )}
                        </div>
                    </div>
                    {props.children}
                </div>
            </div>
            <VerificationInfoPanel customTitle={window.location.pathname === '/zun' ? 'Audited' : 'Audited & formally verified'} />
        </Col>
    );
};
