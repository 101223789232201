import {
    contractAddresses,
    zunUsdZapAddress,
    zunEthApsAddress,
    wethAddress,
    frxEthAddress,
    crvUsdAddress,
} from '../sushi/lib/constants';
import sepoliaAbi from '../actions/abi/sepolia/controller.json';
import { Address, createPublicClient, http } from 'viem';
import { mainnet, sepolia } from 'wagmi';
import { infuraRpcTransportUrl } from '../config';
import angelsList from '../actions/vesting/0x5bA1AF2D13358209A636581252AAe50a3bBCB216.json';
import teamList from '../actions/vesting/0x261732D982DcA7385fcc0788e434fAE67746acEA.json';
import llamaList from '../actions/vesting/0x190B01C325AB18050F13db7e524503336473F931.json';
import ambassadorsList from '../actions/vesting/0x7ba745D6ee5c19d4284ee6dF5cE3c3Ccf147C283.json';
import compensFirstList from '../actions/vesting/0x25488a987CFBe173DAa980B43b6696DC5f8428d4.json';
import compensSecondList from '../actions/vesting/0x7bc2c885916a074955E9550F8b25995811a88853.json';
import lostLlamaList from '../actions/vesting/0x4750aC9d1338Be185f2f6b8cD200f2e8746d34D5.json';
import newList from '../actions/vesting/0x51f4594024327fD2E3d4E7BDdD7174237f038F7C.json';


export const getZunamiAddress = (chainId: number): Address => {
    let address: Address = contractAddresses.zunami[chainId];
    return address;
};

export const getZunUsdAddress = (chainId: number): Address => {
    let address: Address = contractAddresses.zunUSD[chainId] ?? contractAddresses.zunUSD[1];
    return address;
};

export const getZunEthAddress = (chainId: number): Address => {
    let address: Address = contractAddresses.zunEth[chainId] ?? contractAddresses.zunEth[1];
    return address;
};

export const getZunEthApsAddress = (chainId: number | undefined): Address => {
    if (!chainId) {
        return zunEthApsAddress;
    }

    return contractAddresses.ethAps[chainId] ?? contractAddresses.ethAps[1];
};

export const getZunUsdApsAddress = (chainId: number | undefined): Address => {
    if (!chainId) {
        return contractAddresses.aps[1];
    }

    let address: Address = contractAddresses.aps[chainId] ?? contractAddresses.aps[1];
    return address;
};

export const getWethAddress = () => {
    return wethAddress;
};

export const getFrxEthAddress = () => {
    return frxEthAddress;
};

export const getCrvUsdAddress = () => {
    return crvUsdAddress;
};

export const getRewardManagerAddress = () => {
    return '0x7991F9890203488E7818CD6D3AeAC7A97959f993';
};

export const getZapAddress = (
    chainId: number | undefined,
    stakingMode: string = 'UZD'
): Address => {
    if (stakingMode === 'ZETH') {
        return contractAddresses.zapEth[chainId ?? 1] ?? contractAddresses.zapEth[1];
    }

    if (!chainId) {
        return zunUsdZapAddress;
    }

    return contractAddresses.zap[chainId] ?? contractAddresses.zap[1];
};

export const getZunStakingAddress = (chainId: number | undefined): Address => {
    if (!chainId) {
        return contractAddresses.staking[1];
    }

    let address: Address = contractAddresses.staking[chainId] ?? contractAddresses.staking[1];
    return address;
};

export const getZunDaoAddress = (chainId: number | undefined): Address => {
    if (!chainId) {
        return contractAddresses.dao[1];
    }

    let address: Address = contractAddresses.dao[chainId] ?? contractAddresses.dao[1];
    return address;
};

export const getZunTokenAddress = (chainId: number | undefined): Address => {
    if (!chainId) {
        return contractAddresses.zun[1];
    }

    let address: Address = contractAddresses.zun[chainId] ?? contractAddresses.zun[1];
    return address;
};

export const getStableUsdZapAddress = (chainId: number | undefined): Address => {
    if (!chainId) {
        return contractAddresses.stableZapUSD[1];
    }

    let address: Address = contractAddresses.stableZapUSD[chainId] ?? contractAddresses.stableZapUSD[1];
    return address;
};

export const getStableEthZapAddress = (chainId: number | undefined): Address => {
    if (!chainId) {
        return contractAddresses.stableZapETH[1];
    }

    let address: Address = contractAddresses.stableZapETH[chainId] ?? contractAddresses.stableZapETH[1];
    return address;
};

export const isBSC = (chainId: number | undefined) => chainId === 56;
export const isETH = (chainId: number | undefined) => chainId === 1;
export const isSEP = (chainId: number | undefined) => chainId === sepolia.id;
export const isPLG = (chainId: number | undefined) => chainId === 137;

export const getScanAddressByChainId = (chainId: number | undefined) => {
    let address = 'etherscan.io';

    switch (chainId) {
        case 56:
            address = 'bscscan.com';
            break;
        case 137:
            address = 'polygonscan.com';
            break;
        case sepolia.id:
            address = 'sepolia.etherscan.io';
            break;
    }

    return address;
};

export function getAbiByChainId(chainId: number) {
    let result = sepoliaAbi;

    switch (chainId) {
        case 1:
            result = sepoliaAbi;
            break;
    }

    return result;
}

function getChainById(chainId: number) {
    let result: any = mainnet;

    switch (chainId) {
        case sepolia.id:
            result = sepolia;
            break;
    }

    return result;
}

let rpcClient: any = null;

function getCachedClient() {
    return rpcClient;
}

function setCachedClient(client: any) {
    rpcClient = client;
}

export function getChainClient(chainId: number = 1) {
    let result = getCachedClient();

    if (!result) {
        result = createPublicClient({
            chain: getChainById(chainId),
            transport: http(infuraRpcTransportUrl),
        });

        setCachedClient(result);
        return result;
    }

    return result;
}

export function isDev() {
    return window.location.hostname === 'localhost';
}

export function getUpdateInterval() {
    return isDev() ? 30000 : 10000;
}

export function getVestingEligibility(address: Address) : Array<Address> {
    const eligibles: Array<Address> = [];

    if (angelsList.indexOf(address.toUpperCase()) !== -1) {
        eligibles.push('0x5bA1AF2D13358209A636581252AAe50a3bBCB216');
    }

    if (teamList.indexOf(address.toUpperCase()) !== -1) {
        eligibles.push('0x261732D982DcA7385fcc0788e434fAE67746acEA');
    }

    if (llamaList.indexOf(address.toUpperCase()) !== -1) {
        eligibles.push('0x190B01C325AB18050F13db7e524503336473F931');
    }

    if (ambassadorsList.indexOf(address.toUpperCase()) !== -1) {
        eligibles.push('0x7ba745D6ee5c19d4284ee6dF5cE3c3Ccf147C283');
    }

    if (compensFirstList.indexOf(address.toUpperCase()) !== -1) {
        eligibles.push('0x25488a987CFBe173DAa980B43b6696DC5f8428d4');
    }

    if (compensSecondList.indexOf(address.toUpperCase()) !== -1) {
        eligibles.push('0x7bc2c885916a074955E9550F8b25995811a88853');
    }

    if (lostLlamaList.indexOf(address.toUpperCase()) !== -1) {
        eligibles.push('0x4750aC9d1338Be185f2f6b8cD200f2e8746d34D5');
    }

    if (newList.indexOf(address.toUpperCase()) !== -1) {
        eligibles.push('0x51f4594024327fD2E3d4E7BDdD7174237f038F7C');
    }

    return eligibles;
}