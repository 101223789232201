import './Preloader.scss';

interface PreloaderProps extends React.HTMLProps<HTMLDivElement> {
    onlyIcon?: boolean;
    text?: string;
}

export const Preloader : React.FC<PreloaderProps> = ({
    onlyIcon,
    className,
    text,
    ...props
}) => {
    let classNames = ['Preloader', className].join(' ');
    let content = text ? text : 'Please, wait...';

    return (
        <div className={classNames} {...props}>
            <img src="/preloader.gif" alt="..." />
            {!onlyIcon && <span>{content}</span>}
        </div>
    );
};
