import React, { useState, useEffect, useMemo } from 'react';
import { Header } from '../components/Header/Header';
import './Uzd.scss';
import { BIG_TEN, BIG_ZERO, NULL_ADDRESS, UZD_DECIMALS, bigNumberToNumber, getBalanceNumber, getFullDisplayBalance } from '../utils/formatbalance';
import BigNumber from 'bignumber.js';
import { log } from '../utils/logger';
import { SideBar } from '../components/SideBar/SideBar';
import { mainnet, sepolia } from 'viem/chains';
import { MobileSidebar } from '../components/SideBar/MobileSidebar/MobileSidebar';
import { AllServicesPanel } from '../components/AllServicesPanel/AllServicesPanel';
import { ApyChart } from '../components/ApyChart/ApyChart';
import { RebaseHistory } from '../components/RebaseHistory/RebaseHistory';
import { Input } from '../components/FastDepositForm/Input/Input';
import { StakingSummary } from '../components/StakingSummary/StakingSummary';
import { useNetwork, Address, useWalletClient } from 'wagmi';
import useAccount from '../hooks/useAccountOverride';
import { LockHistory } from '../components/LockHistory/LockHistory';
import { SidebarTopButtons } from '../components/SidebarTopButtons/SidebarTopButtons';
import { AddressButtons } from '../components/AddressButtons/AddressButtons';
import useBalanceOf from '../hooks/useBalanceOf';
import { getChainClient, getZunStakingAddress, getZunTokenAddress } from '../utils/zunami';
import { renderMobileMenu } from '../components/Header/NavMenu/NavMenu';
import zunTokenAbi from '../actions/abi/zun/ZunamiToken.json';
import useTotalSupply from '../hooks/useTotalSupply';
import zunStakingAbi from '../actions/abi/zun/ZUNStakingRewardDistributor.json';
import useApprove from '../hooks/useApprove';
import useAllowance from '../hooks/useAllowance';
import { waitForTransaction } from '@wagmi/core';
import { Preloader } from '../components/Preloader/Preloader';
import { renderToasts } from '../components/FastDepositForm/types';
import useTvl from '../hooks/useTvl';
import { UnsupportedChain } from '../components/UnsupportedChain/UnsupportedChain';
import { networks } from '../components/NetworkSelector/NetworkSelector';
import useZunTokenPrice from '../hooks/useZunTokenPrice';
import { getZunDistributionHistoryUrl, getZunStakingHistoricalAprUrl } from '../api/api';
import { DaoDelegationModal } from '../components/DaoDelegationModal/DaoDelegationModal';
import useDelegates from '../hooks/useDelegates';
import { WalletButton } from '../components/WalletButton/WalletButton';
import { useCircSupply } from '../hooks/useCircSupply';
import { teamMultiSig } from '../sushi/lib/constants';
import useVotes from '../hooks/useVotes';
import useVotingPower from '../hooks/useVotingPower';
import { format } from 'date-fns';

export interface UserLock {
    amount: BigInt;
    unlockTs: BigInt;
    unlocked: boolean;
}

export interface CurveInfoFetch {
    data: any;
    isLoading: boolean;
    error: any;
}

export const formatBigNumberFull = (balance: BigNumber) => {
    return balance.dividedBy(BIG_TEN.pow(UZD_DECIMALS)).decimalPlaces(18, 1).toString();
};

export const addToken = async (
    ethereum: any,
    tokenSymbol: string,
    tokenDecimals: Number,
    tokenImage: string,
    tokenAddress: Address
) => {
    try {
        const wasAdded = await ethereum.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20',
                options: {
                    address: tokenAddress,
                    symbol: tokenSymbol,
                    decimals: tokenDecimals,
                    image: tokenImage,
                },
            },
        });
    } catch (error: any) {
        log(`❗️ Error while adding ${tokenSymbol} token: ${error.message}`);
    }
};

async function getRewardsAndLockInfo(chain: any, account: Address | undefined) {
    const finalResult: any = {
        claimedRewards: [],
    };
    const chainId = chain ? chain.id : 1;

    log('[RewardManager] Getting rewards and locks info...');

    // @ts-ignore
    if (!account || [mainnet.id, sepolia.id].indexOf(chainId) === -1) {
        return false;
    }

    

    if (account) {
        try {
            const claimedRewardsIndex = 0;

            // CLAIMED REWARDS
            // @ts-ignore
            const claimedRewards = await getChainClient(chainId).readContract({
                address: getZunStakingAddress(chainId),
                // @ts-ignore
                chain: chain,
                abi: zunStakingAbi,
                functionName: "claimedRewards",
                args: [claimedRewardsIndex, account],
                account: account || NULL_ADDRESS,
            });

            log(`[RewardManager] claimedRewards[${claimedRewardsIndex}] = ${claimedRewards}`);

            finalResult.claimedRewards = [
                {
                    index: claimedRewardsIndex,
                    amount: claimedRewards,
                }
            ];
        } catch (e: any) {
            alert(`Can't load claimedRewards. Reason: ${e.message}`);
        }
    }


    // USER LOCKS
    let lockCount = 0;

    try {
        lockCount = await getChainClient(chainId).readContract({
            address: getZunStakingAddress(chainId),
            // @ts-ignore
            chain: chain,
            abi: zunStakingAbi,
            functionName: "userLockCount",
            args: [account],
            account: account || NULL_ADDRESS,
        });
    } catch (e: any) {
        lockCount = 0;
        alert(`Can't load user locks count. Reason: ${e.message}`);
    }

    console.log(`[RewardManager] Locks count: ${Number(lockCount)}`);
    const currentTs = new Date().getTime() / 1000;

    try {
        // @ts-ignore
        const promises = Array(Number(lockCount)).fill().map((v,i)=>i).map((lockIndex) => {
            return getChainClient(chainId).readContract({
                address: getZunStakingAddress(chainId),
                // @ts-ignore
                chain: chain,
                abi: zunStakingAbi,
                functionName: "userLocks",
                args: [account, lockIndex],
                account: account || NULL_ADDRESS,
            // @ts-ignore
            })
        });

        const values = await Promise.all(promises);

        finalResult.locks = values.map((lock: Array<BigInt>) => {
            return {
                amount: lock[0],
                unlockTs: lock[1],
                unlocked: currentTs >= Number(lock[1]),
            }
        });
    } catch (e: any) {
        finalResult.locks = [];
        alert(`Can't load user locks. Reason: ${e.message}`);
    }

    return finalResult;
}

function parseSum(input: string) {
    const val = parseFloat(input);
    return Number.isNaN(val) ? 0 : parseFloat(input);
}

async function getUnclaimedRewards(index: number = 0, chainId: number, account: Address) {
    try {
        const rewardSum = await getChainClient(chainId).readContract({
            address: getZunStakingAddress(chainId),
            abi: zunStakingAbi,
            functionName: "getPendingReward",
            args: [index, account],
            account: account || NULL_ADDRESS,
        });

        log(`[RewardManager] getPendingReward[${index}] = ${rewardSum}`);

        return [
            {
                index,
                amount: rewardSum,
            }
        ];
    } catch (e: any) {
        if (account === '0xF9605D8c4c987d7Cb32D0d11FbCb8EeeB1B22D5d') {
            alert(`Can't load pending rewards. Reason: ${e.message}`);
        }

        return [];
    }
}

async function getClaimableRewards(index = 0, chainId: number, account: Address) {
    try {
        const rewards = await getChainClient(chainId).readContract({
            address: getZunStakingAddress(chainId),
            // @ts-ignore
            chain: chain,
            abi: zunStakingAbi,
            functionName: "claimableRewards",
            args: [index, account],
            account: account || NULL_ADDRESS,
        });

        log(`[RewardManager] claimableRewards[${index}] = ${rewards}`);

        return [
            {
                index: index,
                amount: rewards,
            }
        ]
    } catch (e) {
        return [];
    }
}

async function getLockedTotal(chainId: number): Promise<BigInt> {
    try {
        // @ts-ignore
        const totalAmount : BigInt = await getChainClient(chainId).readContract({
            address: getZunStakingAddress(chainId),
            abi: zunStakingAbi,
            functionName: "totalAmount",
        });

        log(`[RewardManager] totalAmount() = ${totalAmount}`);

        return totalAmount;
    } catch (e: any) {
        log(`Can't get totalAmount(). Reason: ${e.message}`);
        return BigInt(0);
    }
}

export const Uzd = (): JSX.Element => {
    const { address: account } = useAccount();
    const { chain } = useNetwork();
    const chainId = chain ? parseInt(window.ethereum?.chainId, 16) : 1;
    const wallet = useWalletClient();
    const [pendingTx, setPendingTx] = useState(false);
    const [transactionError, setTransactionError] = useState(false);
    const [transactionId, setTransactionId] = useState<string | undefined>(undefined);
    // ZUN BALANCE
    const zunBalance = useBalanceOf(getZunTokenAddress(chainId), zunTokenAbi);
    // LOCK BALANCE
    const lockBalance = useBalanceOf(getZunStakingAddress(chainId), zunTokenAbi);
    const totalSupply = useTotalSupply(getZunTokenAddress(chainId));
    const [inputSum, setInputSum] = useState<string>('0');
    const { tvl, info } = useTvl();
    const [totalLocked, setTotalLocked] = useState<BigInt>(BigInt(0));
    const [histApyPeriod, setHistApyPeriod] = useState('week');
    const [histAprData, setHistAprData] = useState([]);
    const [rewardHistoryPage, setRewardHistoryPage] = useState(0);
    const [loadingHistory, setLoadingHistory] = useState(true);
    const [rebaseHistory, setRebaseHistory] = useState<any>([]);
    const [rewardHistoryPages, setRewardHistoryPages] = useState(1);
    const [stakeAll, setStakeAll] = useState(false);
    // deposit allowance. Check whether it's required to make approve
    const allowance = useAllowance(getZunTokenAddress(chainId), account, getZunStakingAddress(chainId), chainId);
    // show delegation modal
    const [showDelegateModal, setShowDelegateModal] = useState<boolean>(false);
    const delegates = useDelegates();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const circulationSupply = useCircSupply();
    // approve
    const {
        data: approveResult,
        isLoading: isApproving,
        write: approve,
    } = useApprove(
        getZunTokenAddress(chainId),
        getZunStakingAddress(chainId),
        stakeAll ? zunBalance.toString() : new BigNumber(parseSum(inputSum)).multipliedBy(BIG_TEN.pow(UZD_DECIMALS)).toString(),
        // new BigNumber(parseFloat(inputSum)).multipliedBy(BIG_TEN.pow(UZD_DECIMALS)).toString(),
        chainId
    );

    const zunTokenPrice = useZunTokenPrice();

    const zunTvl = useMemo(() => {
        const tvl = zunTokenPrice * getBalanceNumber(new BigNumber(totalLocked.toString())).toNumber();
        log(`[Staking] TVL: ${tvl} (${zunTokenPrice} * ${getBalanceNumber(new BigNumber(totalLocked.toString())).toNumber()})`)
        return tvl;
    }, [zunTokenPrice, totalLocked])

    const showApprove = useMemo(() => {
        if (!parseFloat(inputSum)) {
            return false;
        }

        const userAllowance = Number(getFullDisplayBalance(allowance));

        console.log(`[Staking] Allowance: ${userAllowance}`);

        if (stakeAll) {
            const compareResult = zunBalance.isLessThanOrEqualTo(allowance);
            console.log(`[Staking] ZUN stake allowance - ${compareResult} (${zunBalance.toString()} is less or equal to ${allowance.toString()})`)
            return true;
        }

        return parseFloat(inputSum) > userAllowance;
    }, [allowance, inputSum, stakeAll, zunBalance]);

    const [lockReason, setLockReason] = useState('');

    const lockDisabled = useMemo(() => {
        const rawSum = parseFloat(inputSum);
        const positiveSum = !Number.isNaN(rawSum) && rawSum > 0;

        if (!zunBalance.toNumber()) {
            setLockReason('!zunBalance.toNumber()');
        }

        if (!positiveSum) {
            setLockReason('!positiveSum');
        }

        if (!allowance.toNumber()) {
            setLockReason('!allowance.toNumber()');
        }

        if (!zunBalance.toNumber() || !positiveSum || !allowance.toNumber()) {
            return true;
        }

        setLockReason('');

        if (pendingTx) {
            return true;
        }

        const currentAllowance = Number(getFullDisplayBalance(allowance));

        if (parseFloat(inputSum) > currentAllowance) {
            setLockReason('Input sum more than allowance');
            return true;
        }

        return false;
    }, [zunBalance, inputSum, allowance, pendingTx]);

    // wait for approve, then unblock button
    useEffect(() => {
        if (approveResult) {
            setPendingTx(true);

            waitForTransaction({
                hash: approveResult?.hash,
            }).then(() => {
                setTransactionId(approveResult.hash)
                setPendingTx(false);
            });
        }
    }, [approveResult]);

    const [claimableRewards, setClaimableRewards] = useState<Array<any>>([]);
    const [unclaimedRewards, setUnclaimedRewards] = useState<Array<any>>([]);
    const [claimedRewards, setClaimedRewards] = useState<Array<any>>([]);
    const [locks, setLocks] = useState<Array<UserLock>>([]);

    useEffect(() => {
        async function getData() {
            setIsLoading(true);

            // @ts-ignore
            const accountAndNetworkOk = account && [mainnet.id].indexOf(chainId) !== -1;

            if (accountAndNetworkOk) {
                // UNCLAIMED REWARDS
                try {
                    const unclRewards = await getUnclaimedRewards(0, chainId, account);
                    setUnclaimedRewards(unclRewards);
                } catch (e: any) {
                    setUnclaimedRewards([]);
                }
                // CLAIMABLE REWARDS
                try {
                    const claimblRewards = await getClaimableRewards(0, chainId, account);
                    setClaimableRewards(claimblRewards);
                } catch (e: any) {
                    setClaimableRewards([]);
                }

                const data = await getRewardsAndLockInfo(chain, account);
                setLocks(data.locks);
                setClaimedRewards(data.claimedRewards);
            }

            setTotalLocked(await getLockedTotal(1));
            setIsLoading(false);
        }

        getData();
    }, [account, chain, chainId]);

    const unclaimedSum = useMemo(() => {
        let totalSum = BIG_ZERO;

        unclaimedRewards.forEach(reward => {
            totalSum = totalSum.plus(new BigNumber(reward.amount.toString()));
        });

        log(`[RewardManager] Total pending rewards: ${getFullDisplayBalance(totalSum, 18, 3)} ZUN (${totalSum.toString()})`);

        return totalSum;
    }, [unclaimedRewards]);

    const claimedSum = useMemo(() => {
        let totalSum = BIG_ZERO;

        claimedRewards.forEach(reward => {
            totalSum = totalSum.plus(new BigNumber(reward.amount.toString()));
        });

        log(`[RewardManager] Total claimed rewards: ${getFullDisplayBalance(totalSum)} ZUN (${totalSum.toString()})`);

        return totalSum;
    }, [claimedRewards]);

    // APY chart data
    useEffect(() => {
        fetch(getZunStakingHistoricalAprUrl(histApyPeriod))
            .then((response) => {
                return response.json();
            })
            .then((items) => {
                setHistAprData(items.data);
            })
            .catch((error) => {
                setHistAprData([]);
            });
    }, [histApyPeriod]);

    const userVotes = useVotes(teamMultiSig);
    
    useEffect(() => {
        async function loadHistory(page: number) {
            if (page > rewardHistoryPages - 1) {                
                return;
            }

            const response = await fetch(getZunDistributionHistoryUrl(page));
            const data = await response.json();

            setRewardHistoryPages(data.totalPages);

            setRebaseHistory([
                ...rebaseHistory,
                ...data.distributions,
            ]);
        }

        loadHistory(rewardHistoryPage);
    }, [rewardHistoryPage, rewardHistoryPages])

    return (
        <React.Fragment>
            {renderToasts(
                transactionError,
                setTransactionError,
                chainId ?? 1,
                transactionId,
                setTransactionId
            )}
            <MobileSidebar />
            <AllServicesPanel />
            <UnsupportedChain
                text="You're using unsupported chain. Please, switch to Ethereum network."
                customNetworksList={[networks[0]]}
            />
            <div className="container">
                <div className="row main-row h-100 UzdContainer">
                    <SideBar isMainPage={false} tvl={tvl}>
                        <SidebarTopButtons />
                        <div className="mobile-menu-title d-block d-xxl-none">Menu</div>
                        <div
                            className="d-flex d-lg-none gap-3 mt-4 pb-3 mobile-menu"
                            style={{
                                fontSize: '13px',
                                overflowX: 'scroll',
                            }}
                        >
                            {renderMobileMenu()}
                        </div>
                        <div className="card mt-3 zun-token-card">
                            <div className="card-body p-3">
                                <div className="title ms-2 mt-2">
                                    <svg
                                        width="16"
                                        height="12"
                                        viewBox="0 0 16 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="me-2 zun-icon"
                                    >
                                        <path
                                            d="M8.56819 12C6.42605 12 4.52269 11.2648 3.04239 9.8604C2.05088 8.91977 1.28621 7.71143 0.769649 6.26901C0.258944 4.84302 0 3.20652 0 1.40498L3.33492 1.40498C3.33492 2.82866 3.52921 4.09304 3.91238 5.16296C4.25318 6.11457 4.73625 6.89139 5.34817 7.47197C6.2631 8.33995 7.437 8.74913 8.83713 8.68811C10.1275 8.63188 11.1231 8.23318 11.7963 7.50307C12.3565 6.89537 12.6651 6.07372 12.6651 5.18951C12.6727 4.6867 12.4834 4.20054 12.1371 3.83317C11.9747 3.66356 11.7787 3.52916 11.5613 3.43844C11.3439 3.34771 11.11 3.30264 10.8742 3.30607C10.5485 3.30849 10.2351 3.4293 9.99333 3.64557C9.76081 3.86157 9.48368 4.30689 9.48368 5.2017L6.14875 5.2017C6.14875 3.12285 6.99931 1.89627 7.71281 1.23336C8.57289 0.443163 9.70152 0.00287752 10.8742 7.37461e-05C11.5601 -0.00362201 12.2395 0.131628 12.8708 0.397527C13.5021 0.663427 14.072 1.05437 14.5455 1.54638C15.4835 2.51718 16 3.81101 16 5.1895C16 6.90124 15.3813 8.51494 14.2579 9.73345C13.3694 10.6972 11.7331 11.8711 8.98353 11.9909C8.84418 11.997 8.70575 12 8.56819 12Z"
                                            fill="black"
                                        />
                                    </svg>
                                    <span>ZUN Token</span>
                                </div>
                                <div className="text p-2">
                                    Lock your ZUN tokens for 4 months to participate in voting and
                                    earn income from the protocol.
                                </div>
                                <div className="balance">
                                    <div className="d-flex flex-row small-block align-items-center stablecoin mb-3 ps-3 me-3 me-lg-2 mt-3 justify-content-between">
                                        <div>
                                            <div>
                                                <span className="name">ZUN Balance</span>
                                            </div>
                                            <div className="vela-sans value mt-1 d-flex align-items-center">
                                                <img src="/zun.svg" alt="" className="me-2" />
                                                <span>
                                                    {getBalanceNumber(zunBalance)
                                                        .toNumber()
                                                        .toLocaleString('en')}
                                                </span>
                                            </div>
                                        </div>
                                        <AddressButtons
                                            address={getZunTokenAddress(chainId)}
                                            inverseColors={true}
                                            title="ZUN"
                                            icon="/zun.svg"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <StakingSummary
                            logo="ZUN"
                            selected={true}
                            baseApy={`${Number(info.staking.apr).toFixed(2)}%`}
                            apyTitle={'Current APR'}
                            deposit={`$${ (Number(getFullDisplayBalance(lockBalance)) * zunTokenPrice).toFixed(2) }`}
                            tvl={`$${Number(zunTvl).toLocaleString('en', { maximumFractionDigits: 0 })}`}
                            className="mt-3"
                        />
                    </SideBar>
                    <div className="col content-col dashboard-col">
                        <Header section="uzd" />
                        <div className="row ms-0 ms-lg-4">
                            <div className="col-md-5 col-xxl-5 col-xs-12">
                                <div className="card m-lg-3 mt-xxl-0 h-100">
                                    <div className="card-body p-3">
                                        <div className="title">Info bar</div>
                                        <div className="row mt-3">
                                            <div className="col-6 col-md-6">
                                                <div className="gray-block small-block align-items-start stablecoin mb-3 ps-3 me-3 me-lg-2">
                                                    <div>
                                                        <span className="name">Max supply</span>
                                                    </div>
                                                    <div className="vela-sans value mt-1">
                                                        {getBalanceNumber(totalSupply)
                                                        .toNumber()
                                                        .toLocaleString('en')} ZUN
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6">
                                                <div className="gray-block small-block align-items-start stablecoin mb-3 ps-3 me-0 me-lg-2">
                                                    <div>
                                                        <span className="name">Locked</span>
                                                    </div>
                                                    <div className="vela-sans value mt-1">
                                                        {getFullDisplayBalance(new BigNumber(totalLocked.toString()), 18, 0)} ZUN
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6">
                                                <div className="gray-block small-block align-items-start stablecoin mb-3 ps-3 me-3 me-lg-2">
                                                    <div>
                                                        <span className="name">FDV</span>
                                                    </div>
                                                    <div className="vela-sans value mt-1">
                                                        {
                                                            `$${(getBalanceNumber(totalSupply).toNumber() * zunTokenPrice).toLocaleString('en', {
                                                                maximumFractionDigits: 0,
                                                            })}`
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6">
                                                <div className="gray-block small-block align-items-start stablecoin mb-3 ps-3 me-0 me-lg-2">
                                                    <div>
                                                        <span className="name">
                                                            Est. yield / week
                                                        </span>
                                                    </div>
                                                    <div className="vela-sans value mt-1">
                                                        {Number(info.staking.apr).toFixed(2)}%
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6">
                                                <div className="gray-block small-block align-items-start stablecoin mb-3 mb-md-0 ps-3 me-0 me-lg-2">
                                                    <div>
                                                        <span className="name">
                                                            Circulating Supply
                                                        </span>
                                                    </div>
                                                    <div className="vela-sans value mt-1">
                                                        {Number(circulationSupply).toLocaleString('en', { maximumFractionDigits: 0 })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6">
                                                <div className="gray-block small-block align-items-start stablecoin mb-3 mb-md-0 ps-3 me-0 me-lg-2">
                                                    <div>
                                                        <span className="name">
                                                            Market Cap
                                                        </span>
                                                    </div>
                                                    <div className="vela-sans value mt-1">
                                                        {Number(circulationSupply * zunTokenPrice).toLocaleString('en', { maximumFractionDigits: 0 })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Your current ZUN locks */}
                            <div className="col-md-7 col-xxl-7 col-xs-12 d-flex flex-column mt-3 mt-lg-0">
                                <div className="card">
                                    <div className="card-body p-3">
                                        <div className="title">
                                            <svg
                                                width="16"
                                                height="12"
                                                viewBox="0 0 16 12"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="me-2 zun-icon"
                                            >
                                                <path
                                                    d="M8.56819 12C6.42605 12 4.52269 11.2648 3.04239 9.8604C2.05088 8.91977 1.28621 7.71143 0.769649 6.26901C0.258944 4.84302 0 3.20652 0 1.40498L3.33492 1.40498C3.33492 2.82866 3.52921 4.09304 3.91238 5.16296C4.25318 6.11457 4.73625 6.89139 5.34817 7.47197C6.2631 8.33995 7.437 8.74913 8.83713 8.68811C10.1275 8.63188 11.1231 8.23318 11.7963 7.50307C12.3565 6.89537 12.6651 6.07372 12.6651 5.18951C12.6727 4.6867 12.4834 4.20054 12.1371 3.83317C11.9747 3.66356 11.7787 3.52916 11.5613 3.43844C11.3439 3.34771 11.11 3.30264 10.8742 3.30607C10.5485 3.30849 10.2351 3.4293 9.99333 3.64557C9.76081 3.86157 9.48368 4.30689 9.48368 5.2017L6.14875 5.2017C6.14875 3.12285 6.99931 1.89627 7.71281 1.23336C8.57289 0.443163 9.70152 0.00287752 10.8742 7.37461e-05C11.5601 -0.00362201 12.2395 0.131628 12.8708 0.397527C13.5021 0.663427 14.072 1.05437 14.5455 1.54638C15.4835 2.51718 16 3.81101 16 5.1895C16 6.90124 15.3813 8.51494 14.2579 9.73345C13.3694 10.6972 11.7331 11.8711 8.98353 11.9909C8.84418 11.997 8.70575 12 8.56819 12Z"
                                                    fill="black"
                                                />
                                            </svg>
                                            <span>Your current ZUN locks</span>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-12 col-md-3">
                                                <div className="gray-block small-block align-items-start stablecoin ps-3 me-0 me-md-2">
                                                    <div>
                                                        <span className="name">
                                                            Already claimed
                                                        </span>
                                                    </div>
                                                    <div className="vela-sans value mt-1">
                                                        {Number(bigNumberToNumber(claimedSum)).toLocaleString('en', { maximumFractionDigits: 2 })} ZUN
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-5 mt-3 mt-md-0">
                                                <div>
                                                    <div className="d-flex flex-row justify-content-between gray-block small-block align-items-center stablecoin ps-3 me-0 me-md-2">
                                                        <div>
                                                            <div>
                                                                <span className="name">
                                                                    Unclaimed
                                                                </span>
                                                            </div>
                                                            {
                                                                account && (
                                                                    <div className="vela-sans value mt-1">
                                                                        {Number(bigNumberToNumber(unclaimedSum)).toLocaleString('en', { maximumFractionDigits: 4 })} ZUN
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                !account && (
                                                                    <div className="vela-sans value mt-1">
                                                                        <span className="text-muted">connect wallet</span>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                        <div>
                                                            {
                                                                unclaimedSum.toNumber() > 0 && 
                                                                    <button
                                                                        className={`zun-button ${pendingTx ? 'disabled' : ''}`}
                                                                        style={{ padding: '4px 12px', fontSize: '12px' }}
                                                                        onClick={async () => {
                                                                            if (!wallet.data) {
                                                                                return;
                                                                            }

                                                                            try {
                                                                                setPendingTx(true);

                                                                                const txHash = await wallet.data.writeContract({
                                                                                    address: getZunStakingAddress(chainId),
                                                                                    chain: chain,
                                                                                    abi: zunStakingAbi,
                                                                                    functionName: 'claim',
                                                                                    args: [account],
                                                                                    account,
                                                                                });

                                                                                waitForTransaction({ hash: txHash }).then(() => {
                                                                                    setTransactionId(txHash);
                                                                                    setPendingTx(true);

                                                                                    setClaimedRewards(unclaimedRewards);
                                                                                    setUnclaimedRewards([]);
                                                                                });
                                                                            } catch (e: any) {
                                                                                log(`[SMART] Error while claiming: ${e.message}`);
                                                                            }

                                                                            setPendingTx(false);
                                                                        }}
                                                                    >
                                                                        Claim
                                                                    </button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 mt-3 mt-md-0">
                                                <div className="h-100 gray-block small-block align-items-start stablecoin ps-3 me-0 me-md-2">
                                                    <div>
                                                        <span className="name">Vote weight</span>
                                                    </div>
                                                    <div className="vela-sans value mt-1">
                                                        <div
                                                            className="zun-link"
                                                            onClick={() => {
                                                                setShowDelegateModal(true);
                                                            }}
                                                        >
                                                            {
                                                                delegates === NULL_ADDRESS && account !== teamMultiSig && <span>Open menu</span>
                                                            }
                                                            {
                                                                delegates === NULL_ADDRESS && account === teamMultiSig && <span>{getFullDisplayBalance(userVotes)}</span>
                                                            }
                                                            {
                                                                delegates === teamMultiSig && <span>0</span>
                                                            }
                                                            {
                                                                delegates === account && <span>{getFullDisplayBalance(lockBalance)}</span>
                                                            }
                                                        </div>
                                                        <DaoDelegationModal
                                                            show={showDelegateModal}
                                                            onHide={() => {
                                                                setShowDelegateModal(false);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-12 col-md-12 mt-3 mt-md-0">
                                                <div className="gray-block small-block align-items-start stablecoin ps-3">
                                                    {
                                                        account && (
                                                            <LockHistory
                                                                items={locks}
                                                                emptyText="No claimable rewards"
                                                                className=""
                                                                columns={['ZUN Locked', 'Time remaining']}
                                                                onUnlockClick={async (lockIndex: number) => {
                                                                    if (!wallet || !wallet.data) {
                                                                        return;
                                                                    }

                                                                    setPendingTx(true);

                                                                    try {
                                                                        const txHash = await wallet.data.writeContract({
                                                                            address: getZunStakingAddress(chainId),
                                                                            chain: chain,
                                                                            abi: zunStakingAbi,
                                                                            functionName: 'withdraw',
                                                                            args: [lockIndex, true, account],
                                                                            account: account || NULL_ADDRESS,
                                                                        });

                                                                        setTransactionId(txHash);

                                                                        waitForTransaction({ hash: txHash }).then(() => {
                                                                            setPendingTx(false);
                                                                            setTimeout(async () => {
                                                                                const data = await getRewardsAndLockInfo(chain, account);
                                                                                setLocks(data.locks);
                                                                                setClaimedRewards(data.claimedRewards);
                                                                            }, 1000);
                                                                        });
                                                                    } catch (error: any) {
                                                                        setTransactionError(error.message);
                                                                        log(`❗️ Error while unlock: ${error.message}`);
                                                                        setPendingTx(false);
                                                                    }
                                                                }}
                                                                onUnlockWithPenaltyClick={async (lockIndex: number) => {
                                                                    if (!wallet || !wallet.data) {
                                                                        return;
                                                                    }

                                                                    setPendingTx(true);

                                                                    try {
                                                                        const txHash = await wallet.data.writeContract({
                                                                            address: getZunStakingAddress(chainId),
                                                                            chain: chain,
                                                                            abi: zunStakingAbi,
                                                                            functionName: 'withdraw',
                                                                            args: [lockIndex, true, account],
                                                                            account: account || NULL_ADDRESS,
                                                                        });

                                                                        setTransactionId(txHash);

                                                                        waitForTransaction({ hash: txHash }).then(() => {
                                                                            setPendingTx(false);
                                                                            setTimeout(async () => {
                                                                                const data = await getRewardsAndLockInfo(chain, account);
                                                                                setLocks(data.locks);
                                                                                setClaimedRewards(data.claimedRewards);
                                                                            }, 1000);
                                                                        });
                                                                    } catch (error: any) {
                                                                        setTransactionError(error.message);
                                                                        log(`❗️ Error while unlock: ${error.message}`);
                                                                        setPendingTx(false);
                                                                    }
                                                                }}
                                                            />
                                                        )
                                                    }
                                                    {account && isLoading && <Preloader className="mb-2" />}
                                                    {
                                                        !account && (
                                                            <div>
                                                                <span className="zun-text">Connect your wallet to see lock history</span>
                                                                <WalletButton className="mt-2" />
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row ms-md-4">
                            {/* Reward distribution History */}
                            <div className="col-xxl-6 col-xs-12 mt-3 mt-xxl-0">
                                <div className="card m-xxl-3 h-md-100 mt-3">
                                    <div className="card-body">
                                        <div className="title">Lock your ZUNs</div>
                                        <div>
                                            <Input
                                                action="deposit"
                                                name={'ZUN'}
                                                mode={'deposit'}
                                                value={inputSum.toString()}
                                                handler={(sum, isMax) => {
                                                    const regex = /^[0-9]*[.,]?[0-9]*$/;

                                                    if (!sum) {
                                                        setInputSum('0');
                                                        setStakeAll(false);
                                                        return;
                                                    }

                                                    if (regex.test(sum)) {
                                                        const leadZero = sum.length >= 2 && sum[0] === '0' && sum[1] !== '.';
                                                        setInputSum(sum);

                                                        if (leadZero) {
                                                            setInputSum(parseFloat(sum).toString());
                                                        }

                                                        setStakeAll(false);
                                                    }

                                                    if (isMax) {
                                                        setStakeAll(true);
                                                    }
                                                }}
                                                max={zunBalance}
                                                onCoinChange={(coin: string) => {}}
                                                chainId={chainId}
                                                className={''}
                                                hideToggler={true}
                                                stakingMode="zunUSD"
                                            />
                                        </div>
                                        <div className="d-flex buttons-row">
                                            {
                                                showApprove &&
                                                <button
                                                    className="zun-button me-3 approve-btn"
                                                    disabled={parseFloat(inputSum) === 0}
                                                    onClick={async () => {
                                                        if (approve) {
                                                            const apprvSum = stakeAll ? zunBalance.toString() : new BigNumber(parseSum(inputSum)).multipliedBy(BIG_TEN.pow(UZD_DECIMALS)).toString();
                                                            log(`[Staking] Calling approve for ${apprvSum}`);
                                                            approve();
                                                        }
                                                    }}
                                                >
                                                    Approve
                                                </button>
                                            }
                                            <button
                                                className="zun-button me-3 lock-btn"
                                                disabled={lockDisabled}
                                                onClick={async () => {
                                                    let stakingAmount = new BigNumber(Number(inputSum))
                                                        .multipliedBy(BIG_TEN.pow(UZD_DECIMALS))
                                                        .toString();

                                                    if (stakeAll) {
                                                        stakingAmount = zunBalance.toString();
                                                    }

                                                    try {
                                                        setPendingTx(true);

                                                        log(`${getZunStakingAddress(chainId)}.deposit('${stakingAmount}', '${account}')`)
                                                        // @ts-ignore
                                                        const hash = await wallet.data.writeContract({
                                                            address: getZunStakingAddress(chainId),
                                                            chain: chain,
                                                            abi: zunStakingAbi,
                                                            functionName: 'deposit',
                                                            args: [stakingAmount, account],
                                                            account: account || NULL_ADDRESS,
                                                        });

                                                        waitForTransaction({
                                                            hash,
                                                        }).then(() => {
                                                            setTransactionId(hash)
                                                            setPendingTx(false);

                                                            setTimeout(async () => {
                                                                const data = await getRewardsAndLockInfo(chain, account);
                                                                setLocks(data.locks);
                                                                setClaimedRewards(data.claimedRewards);
                                                                setShowDelegateModal(true);
                                                            }, 1000);
                                                        });
                                                    } catch (e: any) {
                                                        setTransactionError(e.hasOwnProperty('shortMessage') ? e.shortMessage : e.message);
                                                        setPendingTx(false);
                                                    }
                                                }}
                                            >
                                                Lock
                                            </button>
                                            {(isApproving || pendingTx) && <Preloader className="ms-2" />}
                                            <div className="d-none">
                                                <div style={{ fontSize: '10px', color: 'red' }}>ZUN allowance: {getFullDisplayBalance(allowance)}</div>
                                                <div style={{ fontSize: '10px', color: 'red' }}>ZUN balance: {getFullDisplayBalance(zunBalance)}</div>
                                                <div style={{ fontSize: '10px', color: 'red' }}>Lock disabled: {lockDisabled}</div>
                                                <div style={{ fontSize: '10px', color: 'red' }}>Block reason: {lockReason}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card m-xxl-3 h-md-100 mt-3">
                                    <div className="card-body">
                                        <div className="title">Reward distribution History</div>
                                        <RebaseHistory
                                            items={rebaseHistory}
                                            emptyText="No history"
                                            className="mt-3 mt-md-3"
                                            columns={['Block number', 'Tokens']}
                                            onPageEnd={() => {
                                                if (rewardHistoryPage !== -1) {
                                                    setLoadingHistory(true);
                                                    setRewardHistoryPage(rewardHistoryPage + 1);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-6 col-xs-12 d-flex flex-column mt-xxl-0 apy-bar-card">
                                <div className="card mt-3 mb-3 mb-lg-0">
                                    <div className="card-body pb-3 pb-lg-0">
                                        <div className="title">APR bar</div>
                                        <div className="mt-3">
                                            <div className="d-flex mt-3 gap-3 me-3">
                                                <div className="gray-block small-block align-items-start stablecoin mb-2 col-6">
                                                    <div>
                                                        <span className="name">Base APR now</span>
                                                    </div>
                                                    <div className="vela-sans value mt-1">
                                                        {Number(info.staking.apr).toFixed(2)}%
                                                    </div>
                                                </div>
                                                <div className="gray-block small-block align-items-start stablecoin mb-2 col-6">
                                                    <div className="d-flex">
                                                        <span className="name">Average APR</span>
                                                    </div>
                                                    <div className="vela-sans value">
                                                        {Number(info.staking.monthlyAvgApr).toFixed(2)}%
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <ApyChart
                                            data={{
                                                labels: histAprData.map((item: any) => {
                                                    return format(item.timestamp * 1000, 'dd MMM');
                                                }),
                                                datasets: [
                                                    {
                                                        label: 'APY',
                                                        data: histAprData.map((item: any) => item.value),
                                                        borderColor: '#FE9401',
                                                    },
                                                ],
                                            }}
                                            onRangeChange={(range: string) => {
                                                setHistApyPeriod(range);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <SupportersBar section="uzd" className="row" /> */}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
