import { log } from '../utils/logger';

const root = 'https://zunami-reward-api.herokuapp.com/api';
export const poolStatsUrl = `${root}/pool/stats`;
export const activeStratsUrl = `${root}/strategy/zunUSD/aps/active-stats`;

export const zunUsdStratsUrl = `${root}/strategy/zunUSD/active-stats`;
export const zunEthHistoryUrl = `${root}/strategy/zunETH/active-stats`;

export const zethStratsUrl = `${root}/strategy/zunETH/active-stats`;

export const uzdStakingInfoUrl = `${root}/pool/aggregated-info`;

export const historicalApyUrl = `${root}/zunami/apy-chart`;
export const zunUsdHistoricalApyUrl = `${root}/pool/zun-usd/apr-chart`;
export const zunUsdApsHistoricalApyUrl = `${root}/pool/zun-usd/aps/apy-chart`;
export const zunEthApsHistoricalApyUrl = `${root}/pool/zun-eth/aps/apy-chart`;
export const zunStakingHistoricalAprUrl = `${root}/staking/zun/apr-chart`;

export const testnetUrl = `${root}/feature`;
export const totalIncomeUrl = `${root}/transfers/total-income`;
export const apsTotalIncomeUrl = `${root}/v2/transfers/total-income`;
export const transHistoryUrl = `${root}/transfers/history`;
export const uzdTransHistoryUrl = `${root}/uzd/transfers/history`;
export const uzdRebaseHistoryUrl = `${root}/uzd/rebase/history`;
export const zethRebaseHistoryUrl = `${root}/zeth/rebase/history`;

export const zethApsHistoryUrl = `${root}/zeth-aps/strategy/active-strategies-stats`;

export const compoundZunUsdInfoUrl = `${root}/compound/zun-usd/`;
export const compoundZunEthInfoUrl = `${root}/compound/zun-eth/`;

export const zunUsdStakingInfoUrl = `${root}/staking/aps/zun-usd/info`;
export const zunEthStakingInfoUrl = `${root}/staking/aps/zun-eth/info`;
export const zunDistributionInfoUrl = `${root}/staking/aps/zun-eth/info`;

export const zunUsdAprChartUrl = `${root}/staking/aps/zun-usd/apr-chart`;
export const zunEthAprChartUrl = `${root}/staking/aps/zun-eth/apr-chart`;

export const zunDaoPrevResultsUrl = `${root}/staking/zun/distributions/votes`;
export const curvePoolsApyUrl = 'https://api.curve.fi/api/getFactoryAPYs?version=2';

const BSC_NETWORK_ID = 56;
const POLYGON_NETWORK_ID = 137;

export const getZunAprChart = (coin: string): string => {
    return coin === 'zunUSD' ? zunUsdAprChartUrl : zunEthAprChartUrl;
}

export const getZunUsdApsStratsUrl = (): string => {
    return activeStratsUrl;
};

export const getZunEthApsStratsUrl = (): string => {
    return `${root}/strategy/zunETH/aps/active-stats`;
};

export const getZunEthStratsUrl = (): string => {
    return zethStratsUrl;
};

export const getZunUsdStratsUrl = (): string => {
    return zunUsdStratsUrl;
};

export const getZethStratsUrl = (): string => {
    return zethStratsUrl;
};

export const getPoolStatsUrl = (poolTypes: string): string => {
    return poolStatsUrl + '?types=' + poolTypes;
};

export const getHistoricalApyUrl = (period: string): string => {
    return `${historicalApyUrl}?period=${period.toUpperCase()}`;
};

export const getZunUsdApsHistoricalApyUrl = (period: string): string => {
    return `${zunUsdApsHistoricalApyUrl}?period=${period.toUpperCase()}`;
};

export const getZunEthApsHistoricalApyUrl = (period: string): string => {
    return `${zunEthApsHistoricalApyUrl}?period=${period.toUpperCase()}`;
};

export const getZunStakingHistoricalAprUrl = (period: string): string => {
    return `${zunStakingHistoricalAprUrl}?period=${period.toUpperCase()}`;
};

export const getZunUsdHistoricalApyUrl = (period: string): string => {
    return `${zunUsdHistoricalApyUrl}?period=${period.toUpperCase()}`;
};

export const getZethHistoricalApyUrl = (period: string): string => {
    return `${root}/zunami/zeth-omnipool-apy-chart?period=${period.toUpperCase()}`;
};

export const getZunEthHistoricalApyUrl = (period: string): string => {
    return `${root}/pool/zun-eth/apr-chart?period=${period.toUpperCase()}`;
};

export const getTestnetStatusUrl = (address: string): string => {
    return `${testnetUrl}?address=${address}`;
};

export const getCompoundInfoUrl = (stakingMode: string = 'UZD'): string => {
    return stakingMode === 'UZD' ? compoundZunUsdInfoUrl : compoundZunEthInfoUrl;
};

export const getApsTotalIncomeUrl = (
    address: string,
    lpTokens: string,
    apsLpTokens: string
): string => {
    return `${apsTotalIncomeUrl}?address=${address.toLowerCase()}&lpTokens=${lpTokens}&apsLpTokens=${apsLpTokens}`;
};

export const getTotalIncomeUrl = (
    address: string,
    lpTokens: string,
    chainId: number = 1
): string => {
    let chain = 'ETH';

    switch (chainId) {
        case BSC_NETWORK_ID:
            chain = 'BSC';
            break;
        case POLYGON_NETWORK_ID:
            chain = 'MATIC';
            break;
    }

    return `${totalIncomeUrl}?address=${address.toLowerCase()}&lpTokens=${lpTokens}&chain=${chain}`;
};

export const getRebaseHistoryUrl = (page: number = 0, size: number = 20): string => {
    return `${uzdRebaseHistoryUrl}?page=${page}&size=${size}`;
};

export const getZunDistributionHistoryUrl = (page: number = 0): string => {
    return `${root}/staking/zun/distributions?page=${page}&size=50`;
};

export const getZethRebaseHistoryUrl = (page: number = 0, size: number = 20): string => {
    return `${zethRebaseHistoryUrl}?page=${page}&size=${size}`;
};

export const getTransHistoryUrl = (
    address: string,
    type: string,
    page: number = 0,
    size: number = 50,
    chainId: number = 1,
    historyType: string
): string => {
    let chain = 'ETH';

    switch (chainId) {
        case BSC_NETWORK_ID:
            chain = 'BSC';
            break;
        case POLYGON_NETWORK_ID:
            chain = 'MATIC';
            break;
    }

    const endpointUrl =
        historyType === 'DEPOSIT_WITHDRAW'
            ? `${transHistoryUrl}?address=${address.toLowerCase()}&type=${type}&page=${page}&size=${size}&chain=${chain}`
            : `${uzdTransHistoryUrl}?address=${address.toLowerCase()}&type=${type}&page=${page}&size=${size}`;

    return endpointUrl;
};

export const getBackendSlippage = async (lpAmount: string, tokenIndex: number) => {
    log(
        `Requesting backend slippage (${`${root}/zunami/slippage?tokenIndex=${tokenIndex}&lpAmount=${lpAmount}`})`
    );
    return fetch(`${root}/zunami/slippage?tokenIndex=${tokenIndex}&lpAmount=${lpAmount}`)
        .then((response) => {
            if (response.status !== 200) {
                throw new Error(`Server response: ${response.statusText}, code ${response.status}`);
            }

            return response.json();
        })
        .then((data) => data.slippage)
        .catch((error) => {
            log(`❗️ Error while retrieving slippage: ${error.message}`);
            return '0';
        });
};
